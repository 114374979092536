@import "variables", "media-queries", "mixins", "typography";
.item {
    display: inline-block;
    width: 100%;
}

.typeBullet {
    @include list-bullet;
}

.typeCheck {
    .inner {
        display: flex;
    }

    &:not(:last-child) {
        margin-bottom: 30px;

        @include media-breakpoint(sm) {
            margin-bottom: 15px;
        }
    }

    .icon {
        margin-right: 16px;
    }
}
