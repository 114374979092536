@import "variables", "media-queries", "mixins", "typography";
.section {
    @include section-indent;

    background-image: $gradient-seo;
}

.heading {
    max-width: 100%;

    h2 {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
    }

    margin-bottom: 60px;

    @include media-breakpoint(md) {
        margin-bottom: 40px;
    }

    @include media-breakpoint(sm) {
        margin-bottom: 30px;
    }
}

.itemsWrap {
    margin-bottom: -40px;
}

.item {
    margin-bottom: 40px;
}

.itemsWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 60px;

    @include media-breakpoint(md) {
        grid-template-columns: 1fr;
        grid-gap: 30px;
    }
}

.content {
    table {
        @include table;
    }

    ul > li {
        @include list-bullet;

        strong {
            font-weight: 600;
        }
    }

    ol {
        counter: seo-ol;
        > li {
            counter-increment: seo-ol;
            display: flex;

            &::before {
                content: counter(seo-ol) ".";
                background: transparent;
                color: currentColor;
                margin-right: 0.5ch;
                margin-left: 0.5ch;
            }
        }
    }

    > *:not(:last-child) {
        margin-bottom: 20px;
    }

    :global {
        .col-2 {
            columns: 2;

            @include media-breakpoint(sm) {
                columns: 1;
            }
        }
    }
}

.itemHeading {
    display: flex;
    margin-bottom: 20px;
}

.icon {
    margin-right: 16px;
    margin-top: 8px;

    @include media-breakpoint(md) {
        margin-right: 10px;
        margin-top: 6px;
    }
}
