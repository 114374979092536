@import "variables", "media-queries", "mixins", "typography";
.cardDefault {
    @include card-white;
}

.cardDefaultHover {
    @include card-white-hover;
}

.cardGlass {
    @include card-glass;
}

.cardGlassHover {
    @include card-glass-hover;
}

.cardCTA {
    @include card-cta;
}

.cardCTAHover {
    @include card-cta-hover;
}

.h1 {
    @include h1-big;
}

.h2 {
    @include h2;
}

.h3 {
    @include h3-big;
}

.h3sm {
    @include h3-small;
}

.h4 {
    @include h4;
}

.bodyBig {
    @include body-big;
}

.bodyBigSemibold {
    @include body-big-semibold;
}
