@import "variables", "media-queries", "mixins", "typography";
.wrapper {
    :global {
        .core-input-number {
            position: relative;
            width: 100%;
            padding: 0;
            height: 40px;
            display: flex;
            font-size: 16px;

            &__label {
                position: absolute;
                top: -24px;
                left: 0;
                line-height: 1;
                color: red;
            }

            &__btn {
                position: relative;
                flex-shrink: 0;
                height: 100%;
                width: 40px;
                color: rgba(0, 0, 0, 0.85);

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 4px);
                    width: 10px;
                    height: 2px;
                    background-color: rgba(0, 0, 0, 0.85);
                }

                &:disabled {
                    cursor: default;

                    &::before,
                    &::after {
                        background-color: rgba(0, 0, 0, 0.25);
                    }
                }

                &--minus {
                    left: 0;
                    z-index: 1;
                    border-right: none;
                }

                &--plus {
                    right: 0;
                    border-left: none;

                    &::before {
                        width: 10px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: calc(50% - 4px);
                        width: 2px;
                        height: 10px;
                        background-color: rgba(0, 0, 0, 0.85);
                    }
                }
            }

            input {
                text-align: center;
                border-radius: 0;
                width: 100%;
                position: relative;
                z-index: 1;
            }
        }
    }
}
