@import "variables", "media-queries", "mixins", "typography";


.reviewItem {
    color: $color-text-primary;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;

    > a {
        display: flex;
        align-items: center;
    }

}


.icon {
    padding: 5px;
    margin-right: 12px;
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    box-shadow: $shadows-general-small;
    border-radius: $radius-small;
    background-color: $color-white;

    @include media-breakpoint(sm) {
        margin-right: 5px;
    }
}
