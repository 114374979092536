@import "variables", "media-queries", "mixins", "typography";


.rating {
   display: flex;
}

.stars {
    //display: grid;
    //grid-template-columns: repeat(5, 1fr);
    //grid-gap: 2px;
    margin-right: 7px;
    height: 12px;
    line-height: 0;
}

.star {
    width: 12px;
    height: 12px;
    font-size: 0;
}


.number {
    font-weight: 700;
    font-size: 12px;
    line-height: 1;
}
