@import "variables", "media-queries", "mixins", "typography";
.tabButtons {
    overflow: auto;

    @include customize-scrollbars();

    @include media-breakpoint(sm) {
        margin-right: -1 * $container-indent-sm;
        margin-left: -1 * $container-indent-sm;
        padding-left: $container-indent-sm;
        padding-right: $container-indent-sm;
    }

    @include media-breakpoint(xs) {
        display: none !important;
    }
}

.tabButtonsInner {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @include media-breakpoint(sm) {
        justify-content: flex-start;
    }
}

.reviewsSelect {
    display: none !important;

    @include media-breakpoint(xs) {
        display: block !important;
        margin-bottom: 30px;
    }
}

.slidersWrap {
    position: relative;
}

.sliderWrap {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    transition: opacity 0.2s;
    pointer-events: none;

    &Active {
        position: relative;
        opacity: 1;
        transition: opacity 0.2s 0.2s;
        pointer-events: auto;
    }
}

.sliderInner {
    position: relative;
    width: 370px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint(md) {
        width: 300px;
    }

    @include media-breakpoint(sm) {
        margin-left: auto;
        margin-right: auto;
    }

    .slider {
        padding-bottom: 60px;
        overflow: visible;

        @include media-breakpoint(sm) {
            padding-bottom: 30px;
        }

        :global {
            .swiper-slide {
                height: auto;
            }
        }
    }
}

.card {
    height: 100%;
}

.mocked {
    display: flex;
    > div {
        flex-shrink: 0;
        margin-right: 30px;

        @include media-breakpoint(xs) {
            margin-right: 15px;
        }
    }
}
