@import "variables", "media-queries", "mixins", "typography";
.tabButton {
    padding-top: 0;
    padding-bottom: 0;
    height: 48px;
    &:not(:last-child) {
        margin-right: 20px;
    }

    &.small {
        font-size: 12px;
        line-height: 1.2;
        padding: 9px 8px;
        height: 32px;
        border-radius: 6px;

        &.dropdown {
            padding-right: 21px;
        }
    }

    &.active {
        .dropdownIcon {
            transform: scale(1, -1) translate(0, 2px);
        }
    }
}

.tabButtonInner {
    display: flex;
    align-items: center;
}

.image {
    display: flex;
    align-items: center;

    + .tabButtonText {
        margin-left: 4px;
    }
}

.dropdownIcon {
    position: absolute;
    color: $color-status-muted;
    right: -1px;
    top: 3px;
}
