@import "variables", "media-queries", "mixins", "typography";
.dropdown {
    :global {
        $this: ".core-dropdown";

        #{$this} {
            position: relative;

            &--open {
                z-index: 10;
                #{$this}__items-wrap {
                    display: block;
                    width: 100%;
                }

                #{$this}__toggle-btn-icon {
                    transform: translateY(-50%) scale(1, -1);
                }

                &#{$this}--open-above {
                    #{$this}__items-wrap {
                        bottom: calc(100% + 5px);
                    }
                }

                &#{$this}--open-below {
                    #{$this}__items-wrap {
                        top: calc(100% + 5px);
                    }
                }
            }

            &--range {
                #{$this}__toggle-btn {
                    padding-left: 40px;
                    padding-right: 40px;
                    text-align: center;
                }

                #{$this}__items-wrap {
                    width: 400px;
                    left: 50%;
                    transform: translateX(-50%);
                    max-height: 375px;
                }

                #{$this}__items-list {
                    padding: 10px;
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                }

                #{$this}__link {
                    font-size: 14px;
                    line-height: 22px;
                    text-align: center;
                    height: 50px;
                }
            }

            &__items-wrap {
                display: none;
                left: 0;
                position: absolute;
                max-height: 205px;
                overflow: auto;
                background-color: #fff;
            }

            &__items-list {
                padding: 4px 0;
            }

            &__link {
                display: block;
                width: 100%;
                text-align: left;
                padding: 5px 12px;
                font-size: 14px;
                line-height: 22px;
                transition: background 0.3s ease;

                &:hover:not(#{$this}__link--selected) {
                    background-color: #f5f5f5;
                }

                &--selected {
                    font-weight: 600;
                    background-color: #e6f9ff;
                }
            }

            &__toggle-btn {
                display: block;
                position: relative;
                cursor: pointer;
                min-height: 24px;
                width: 100%;
                text-align: left;
                padding-right: 25px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &__toggle-btn-icon {
                position: absolute;
                right: 10px;
                width: 16px;
                height: 16px;
                top: 50%;
                transform: translateY(-50%);

                &::after {
                    content: "";
                    position: absolute;
                    width: 6px;
                    height: 6px;
                    border-bottom: 2px solid rgba(0, 0, 0, 0.25);
                    border-right: 2px solid rgba(0, 0, 0, 0.25);
                    transform: rotate(45deg);
                    left: 4px;
                    top: 2px;
                }
            }

            &__range-btn {
                position: absolute;
                top: 1px;
                height: calc(100% - 2px);
                width: 39px;

                &::before {
                    content: "";
                    position: absolute;
                    top: 50%;
                    left: calc(50% - 4px);
                    width: 10px;
                    height: 2px;
                    background-color: #000;
                }

                &:disabled {
                    cursor: default;

                    &::before,
                    &::after {
                        background-color: rgba(0, 0, 0, 0.25);
                    }
                }

                &--minus {
                    left: 1px;
                    z-index: 1;
                }

                &--plus {
                    right: 1px;

                    &::before {
                        width: 10px;
                        background-color: rgba(0, 0, 0, 0.85);
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        left: 50%;
                        top: calc(50% - 4px);
                        width: 2px;
                        height: 10px;
                        background-color: rgba(0, 0, 0, 0.85);
                    }
                }
            }
        }
    }
}
