@import "variables", "media-queries", "mixins", "typography";
.calculator {
    max-width: 420px;
    width: 100%;
    color: $color-gray-dark;

    &Premium {
        display: grid;
        grid-gap: 40px;
        grid-template-columns: 420px 1fr;
        position: relative;
        max-width: 100%;
        border-radius: 12px;
        align-items: center;

        @include card-glass;

        @include media-breakpoint(md) {
            display: block;
        }

        .main {
            width: 100%;
            max-width: 410px;
            flex-shrink: 0;
            @include media-breakpoint(md) {
                max-width: none;
            }
        }

        .inner {
            @include media-breakpoint(md) {
                grid-template-columns: repeat(4, 1fr);
            }

            @include media-breakpoint(sm) {
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }

    .main {
        :global {
            .core-calculator-form {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 20px 25px;

                @include media-breakpoint(xs) {
                    grid-template-columns: 1fr;
                    grid-gap: 15px;
                }
            }

            .core-calculator-price {
                color: $color-text-primary;
                font-size: 32px;
                line-height: 1.5;
                font-weight: 700;

                @include media-breakpoint(sm) {
                    font-size: 28px;
                }

                &__dec {
                    margin-left: 0.5ch;
                    top: -7px;
                    position: relative;
                    font-size: 24px;

                    @include media-breakpoint(sm) {
                        top: -6px;
                        font-size: 20px;
                    }
                }
            }

            .core-calculator-form-field {
                &__label {
                    margin-bottom: 10px;
                }

                &__control {
                    border-radius: 10px;
                    min-height: 48px;

                    &:hover {
                        .core-calculator-input {
                            color: $color-basic-primary-hover;
                        }
                        .core-dropdown__toggle-btn,
                        .core-input-number {
                            box-shadow: $shadows-select-hover;
                        }
                        .core-dropdown__toggle-btn-icon:before,
                        .core-dropdown__toggle-btn-icon:after {
                            border-color: $color-basic-primary-hover;
                        }
                    }
                }
            }

            .core-calculator-field-tabs {
                &__links {
                    display: flex;
                    margin-bottom: 10px;
                }

                &__link {
                    display: block;
                    border-radius: 17px;
                    line-height: 1.5;
                    padding: 0 8px;
                    color: var(--color-text-muted);
                    font-weight: 600;

                    &--active {
                        background-color: var(--color-tab-bg);
                        color: var(--color-text-secondary);
                    }
                }
            }

            .core-dropdown {
                position: relative;

                &--open {
                    .core-calculator-input {
                        color: $color-basic-primary-hover;
                    }
                    .core-dropdown__toggle-btn-icon:before,
                    .core-dropdown__toggle-btn-icon:after {
                        border-color: $color-basic-primary-hover;
                    }
                }
                &--open-above {
                    .core-dropdown {
                        &__toggle-btn {
                            border-radius: 0 0 $radius-default $radius-default;
                        }

                        &__items-wrap {
                            bottom: 100%;
                            border-radius: $radius-default $radius-default 0 0;
                        }
                    }
                }

                &--open-below {
                    .core-dropdown {
                        &__toggle-btn {
                            border-radius: $radius-default $radius-default 0 0;
                        }

                        &__items-wrap {
                            top: 50px !important;
                            border-radius: 0 0 $radius-default $radius-default;
                        }
                    }
                }

                &--range {
                    .core-dropdown {
                        &__toggle-btn {
                            padding-left: 30px;
                            padding-right: 30px;
                            text-align: center;
                        }

                        &__items-wrap {
                            width: 100%;
                            left: 0;
                            transform: none;
                            max-height: 205px;
                        }

                        &__items-list {
                            display: block;
                        }
                    }
                }

                &__toggle-btn {
                    @include body-default;
                    display: block;
                    border-radius: $radius-default;
                    width: 100%;
                    color: $color-text-primary;
                    text-align: left;
                    padding: 13px 20px;
                    padding-right: 25px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    transition: border 0.25s;
                    background-color: $color-white;
                    box-shadow: $shadows-form-item;
                    min-height: 50px;
                }

                &__toggle-btn-icon::after {
                    width: 8px;
                    height: 8px;
                    right: 6px;
                    left: auto;
                    border-color: $color-text-primary;
                }

                &__items-wrap {
                    background-color: $color-white;
                    border-radius: 0 0 $radius-default $radius-default;
                    box-shadow: $shadows-general-default;
                    @include customize-scrollbars();
                }

                &__items-list {
                    padding: 5px 7px;
                }

                &__link {
                    display: block;
                    width: 100%;
                    text-align: left;
                    padding: 12px;
                    color: $color-text-primary;
                    @include body-default;
                    border-radius: 10px;

                    &:not(.core-dropdown__link--selected) {
                        &:hover {
                            background-color: $color-section-inverted-bg;
                        }
                    }

                    &--selected {
                        background-color: $color-basic-primary-bg;
                    }
                }
            }

            .core-input-number {
                @include body-default;
                display: flex;
                border-radius: $radius-default;
                width: 100%;
                color: $color-text-primary;
                transition: border 0.25s;
                background-color: $color-white;
                box-shadow: $shadows-form-item;
                min-height: 50px;
            }

            .core-calculator-field-tabs {
                &__link--active {
                    border-radius: $radius-default;
                    color: $color-text-primary;
                    background-color: $color-white;
                    box-shadow: $shadows-form-item;
                }
            }
        }
    }
}

.heading {
    @include body-small-caps;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;

    i {
        margin-right: 10px;
    }
}

.bottom {
    padding-top: 30px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.featuresBlock {
    flex-shrink: 0;

    @include media-breakpoint(md) {
        padding: 20px;
        width: 100%;
        margin-left: 0;
    }
}

.featuresHeading {
    @include body-big-bold;
    margin-top: 15px;
    margin-bottom: 10px;
    color: $color-text-primary;

    @include media-breakpoint(md) {
        text-align: center;
    }

    @include media-breakpoint(sm) {
        font-size: 16px;
    }
}

.featuresSubHeading {
    font-size: 16px;
    margin-bottom: 30px;

    @include media-breakpoint(md) {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    @include media-breakpoint(sm) {
        margin-bottom: 20px;
    }

    @include media-breakpoint(xs) {
        max-width: 100%;
    }
}

.featuresList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding-right: 40px;

    @include media-breakpoint(md) {
        padding-right: 0;
    }

    @include media-breakpoint(xs) {
        grid-template-columns: 1fr;
    }
}

.featuresBtn {
    padding-left: 20px;
    padding-right: 20px;
    white-space: nowrap;
}

.featuresBtn {
    :global {
        svg {
            flex-shrink: 0;
        }
    }
}

.main {
    background-color: $color-misc-card-glass-bg;
    box-shadow: $shadows-card-glass;
    border: 1px solid $color-white;
    border-radius: $radius-default;
    color: $color-gray-dark;
    padding: 25px 30px 25px;
}

.price {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}

.priceInt {
    font-size: 34px;
    color: $color-basic-primary;
    font-weight: 600;
    margin-right: 5px;
}

.priceDec {
    color: $color-basic-primary;
    font-weight: 600;
    position: relative;
    top: -5px;
}

.priceText {
    font-weight: 700;
    text-align: center;
    color: #aab4c6;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 1px;
}

.featuresBlockTop {
    text-align: center;
    padding-right: 40px;

    @include media-breakpoint(md) {
        padding-right: 0;
    }
}
