@import "variables", "media-queries", "mixins", "typography";
.card {
    padding: 20px;

    &:hover {
        .cardIcon {
            color: #fff;
            box-shadow: none;
            background-color: transparent;
        }

        .iconImg {
            opacity: 1;
        }
    }
}

.title {
    display: block;
}

.cardTop {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.cardDirectionVertical {
    .cardTop {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 12px;
    }
}

.cardIcon {
    position: relative;
    z-index: 1;
    transition: color 0.25s;
}

.cardIconWrap {
    position: relative;
}

.iconImg {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 0;
    left: -3px;
    font-size: 0;
    line-height: 1;
    opacity: 0;
    transition: opacity 0.25s;
}

.text {
    @include body-default;
    color: $color-text-secondary;
    flex: 1;

    ul {
        > li {
            position: relative;
            padding-left: 20px;

            &::before {
                content: "";
                position: absolute;
                top: 8px;
                left: 6px;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background-color: currentColor;
            }
        }
    }
}
