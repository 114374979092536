@import "variables", "media-queries", "mixins", "typography";
.section {
    @include section-indent;
}

.heading {
    margin-bottom: 60px;
}

.hr {
    @include hr;
}

.faqItems {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 15px));
    grid-gap: 30px;
    align-items: start;
    margin-bottom: -30px;

    @include media-breakpoint(md) {
        grid-template-columns: 100%;
        grid-gap: 0;
    }
}

.faqItem {
    margin-bottom: 30px;
}
