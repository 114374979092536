@import "variables", "media-queries", "mixins", "typography";
.section {
    color: $color-text-primary;
    background-color: $color-basic-site-bg;
    @include section-indent;
    overflow: hidden;
}

.heading {
    margin-bottom: 60px;
}
