@import "variables", "media-queries", "mixins", "typography";


.card {
    width: 100%;
    padding: 20px;
    background: $color-highlight-card-bg;
    border-radius: $radius-medium;
    margin: 15px 0;
}
