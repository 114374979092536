@import "variables", "media-queries", "mixins", "typography";
.section {
    color: $color-text-primary;
    background-color: $color-basic-site-bg;

    &.typeSecondary {
        .heading {
            margin-left: 0;
            max-width: 100%;

            > * {
                max-width: 100%;
            }
        }

        .container {
            display: grid;
            grid-template-columns: 1fr 240px;
            align-items: center;
            grid-gap: 80px;

            @include media-breakpoint(md) {
                grid-template-columns: 1fr;
                grid-gap: 30px;
            }
        }

        .action {
            margin-left: 0;
            width: 100%;
            @include media-breakpoint(md) {
                max-width: 240px;
                margin-left: auto;
                margin-right: auto;
            }

            @include media-breakpoint(sm) {
                max-width: 100%;
            }
        }

        .icons {
            @include media-breakpoint(sm) {
                max-width: 100%;
            }
        }

        .bottom {
            @include media-breakpoint(sm) {
                align-items: flex-start;
            }
        }
    }
}

.container {
    @include section-indent;
}

.heading {
    margin-bottom: 40px;
}

.bottom {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint(md) {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.action {
    width: 200px;
    flex-shrink: 0;
    margin-left: 40px;

    @include media-breakpoint(sm) {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
    }
}

.bottomText {
    margin-top: 15px;
    @include body-medium;
    color: $color-basic-secondary;
}
