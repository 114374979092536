@import "variables", "media-queries", "mixins", "typography";
.accordionItem {
    box-sizing: border-box;
    transition: border-color 0.3s;

    &.headingBig {
        .heading {
            @include body-big-semibold;
        }
    }

    &.open {
        .headWrapper {
            .heading {
                color: $color-basic-primary;
            }

            button {
                color: $color-basic-primary;
                svg {
                    transform: scaleY(-1);
                }
            }
        }

        .contentWrapper {
            &::before {
                transform: scaleX(1);
            }
        }
    }

    &.typeSmall {
        .headWrapper {
            min-height: auto;
            padding: 15px 30px;

            @include media-breakpoint(sm) {
                padding-left: 50px;
            }
        }
    }
}

.headWrapper,
.content {
    padding: 20px 25px;
}

.headWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 68px;
    box-sizing: border-box;
    cursor: pointer;

    .heading {
        color: $color-text-primary;
        transition: color 0.3s;
        padding-right: 10px;
        display: flex;
        align-items: center;
        @include body-default-semibold;
        width: 100%;
    }

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: inherit;

        svg {
            transition: transform 0.3s;
        }
    }
}

.contentWrapper {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        display: block;
        height: 1px;
        background-color: $color-basic-border;
        top: 0;
        left: 20px;
        right: 20px;
        transform: scaleX(0);
        transform-origin: left center;
        transition: transform 0.3s;
    }

    .content:not(.editor) {
        @include base-article;

        ul > li {
            margin-bottom: 0;
            padding-left: 20px;
        }
    }
}
