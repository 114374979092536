@import "variables", "media-queries", "mixins", "typography";
.card {
    color: $color-text-primary;
    padding: 20px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint(sm) {
        padding: 15px;
    }
}

.top {
    text-align: center;
}

.img {
    margin-bottom: 12px;
}
.heading {
    @include h4;
    margin-bottom: 25px;

    @include media-breakpoint(sm) {
        margin-bottom: 20px;
    }
}

.features {
    margin-bottom: 32px;
}

.feature {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 16px;

    &:not(:last-child) {
        border-bottom: 1px solid $color-misc-hr;
        margin-bottom: 15px;
    }

    &Left {
        display: flex;
        align-items: center;
    }

    &Right {
        flex-shrink: 0;
        display: flex;
        align-items: center;
    }

    &Name {
        @include body-default;
    }

    &Price {
        @include body-default;
        text-decoration: line-through;
        color: $color-status-muted;
        margin-right: 10px;
    }

    &Free {
        font-weight: 700;
        text-transform: uppercase;
        color: $color-green;
    }
}

.total {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    gap: 10px;

    .featureName {
        @include body-big-bold;
    }

    .featurePrice {
        @include body-big-bold;
    }

    .featureFree {
        font-size: 20px;
    }
}

.icon {
    margin-right: 10px;
}
