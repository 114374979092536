@import "variables", "media-queries", "mixins", "typography";
.dropdownResponsive {
    display: block;
    position: relative;
    border-radius: $radius-default;

    &Open {
        z-index: 10;

        .toggleBtnDropdown {
            opacity: 0.6;
        }

        + :global(.dropdownEqualSign) {
            z-index: 11;
        }

        .dropdownLinksSelectBtnIcon {
            transform: scale(1, -1) translateY(12px);
        }
    }

    &Select {
        .toggleBtn {
            min-height: 50px;
        }
        .itemsWrapOpen {
            width: 100%;
        }

        .itemsWrap {
            max-height: 205px;
            overflow: auto;

            @include customize-scrollbars();
        }
    }

    &Range {
        .toggleBtn {
            padding-left: 30px;
            padding-right: 30px;
            text-align: center;
            min-height: 50px;
        }

        .itemsWrapOpen {
            width: 100%;
        }

        .itemsWrap {
            max-height: 205px;
            overflow: auto;

            @include customize-scrollbars();
        }
    }

    &Error {
        .rangeBtn {
            &::before,
            &::after {
                background-color: $color-status-danger;
            }

            &:disabled {
                cursor: default;

                &::before,
                &::after {
                    background-color: $color-status-danger;
                }
            }
        }
    }

    &.dropdownLinks {
        .itemsWrap {
            border-radius: 12px;
            box-shadow: $shadows-general-default;
            left: -30px;
            width: 100%;
            min-width: 240px;
        }

        .itemsList {
            display: grid;
            gap: 30px;
            padding: 30px;
        }

        .item {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 1.4;
            color: $color-text-primary;
            //white-space: nowrap;
            transition: color 0.25s;
            &:hover {
                color: $color-basic-primary-hover;
            }
        }

        &.dropdownResponsiveOpenBelow {
            .itemsWrap {
                top: calc(100% + 4px);
            }
        }
    }

    &.dropdownLinksSelect {
        .itemsWrap {
            box-shadow: $shadows-general-default;
            width: 100%;
            min-width: 240px;
        }

        .itemsList {
            display: grid;
            gap: 1px;
            padding: 5px 10px;
        }

        .item {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 1.4;
            color: $color-text-primary;
            //white-space: nowrap;
            transition: background-color 0.25s;

            a {
                display: block;
                padding: 9px 12px;
                border: 10px;
            }

            &:hover {
                background-color: $color-section-inverted-bg;
            }
        }

        &.dropdownResponsiveOpenBelow {
            .itemsWrap {
                top: 100%;
            }
        }
    }
}

.dropdownLinksSelectBtn {
    display: block;
    position: relative;
    background-color: $color-white;
    box-shadow: $shadows-form-item;
    padding: 9px 20px;
    border-radius: 12px;
    width: 100%;
    text-align: left;

    &Placeholder {
        color: $color-status-muted;
    }

    &Icon {
        color: $color-text-primary;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.dropdownResponsiveOpen.dropdownResponsiveRange,
.dropdownResponsiveOpen.dropdownResponsiveSelect,
.dropdownResponsiveOpen,
.dropdownLinksSelect {
    &.dropdownResponsiveOpenAbove {
        .toggleBtn,
        .dropdownLinksSelectBtn {
            border-radius: 0 0 $radius-default $radius-default;
        }
        .itemsWrap {
            bottom: 100%;
            border-radius: 10px 10px 0 0;
        }
    }

    &.dropdownResponsiveOpenBelow {
        .toggleBtn,
        .dropdownLinksSelectBtn {
            border-radius: $radius-default $radius-default 0 0;
        }

        .itemsWrap {
            top: 100%;
        }
    }
}

.toggleBtn {
    $this: &;
    position: relative;

    &Dropdown {
        padding-right: 30px;
        transition: opacity 0.25s;

        &::before,
        &::after {
            content: "";
            position: absolute;
            background-color: $color-white;
            height: 8px;
            width: 2px;
            right: 20px;
            top: 0;
            transform-origin: center left;
        }

        &::before {
            transform: rotate(-45deg) translate(-2px, 7px);
        }

        &::after {
            transform: rotate(45deg) translate(9px, -3px);
        }

        &:hover {
            opacity: 0.6;
        }
    }

    &Select {
        @include body-default;
        border-radius: $radius-default;
        width: 100%;
        color: $color-text-primary;
        text-align: left;
        padding: 13px 20px;
        padding-right: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: border 0.25s;
        background-color: $color-white;
        box-shadow: $shadows-form-item;

        &#{$this}Error {
            border-color: $color-status-danger;
            color: $color-status-danger;
        }
    }

    &Placeholder {
        color: $color-status-muted;
    }
}

.toggleBtnIcon {
    position: absolute;
    right: 10px;
    width: 20px;
    height: 20px;
    top: 16px;
    fill: $color-text-primary;
}

.itemsWrap {
    display: none;
    left: 0;
    position: absolute;
    background-color: $color-white;
    border-radius: 0 0 $radius-default $radius-default;
    box-shadow: $shadows-general-default;

    &Open {
        display: block;

        &Below {
            top: 22px;
            // animation: appearFromTop 0.25s;
        }

        &Above {
            bottom: 100%;
            // animation: appearFromBottom 0.25s;
        }
    }
}

.itemsList {
    padding-top: 5px;
    padding-bottom: 5px;
    padding: 5px 7px;
}

.toggleBtn {
    display: block;
}

.item {
    &:not(:last-child) {
        margin-bottom: 1px;
    }
}

.link {
    display: block;
    width: 100%;
    text-align: left;
    padding: 12px;
    color: $color-text-primary;
    @include body-default;
    border-radius: 10px;

    &:hover:not(.linkSelected) {
        background-color: $color-section-inverted-bg;
    }

    &Selected {
        background-color: $color-basic-primary-bg;
    }
}

.rangeBtn {
    position: absolute;
    top: 0;
    height: 100%;
    width: 30px;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: calc(50% - 4px);
        width: 10px;
        height: 2px;
        background-color: $color-text-primary;
    }

    &:disabled {
        cursor: default;

        &::before,
        &::after {
            background-color: $color-status-muted;
        }
    }

    &Minus {
        left: 5px;
    }

    &Plus {
        right: 5px;

        &::before {
            width: 10px;
            background-color: $color-text-primary;
        }

        &::after {
            content: "";
            position: absolute;
            left: 50%;
            top: calc(50% - 4px);
            width: 2px;
            height: 10px;
            background-color: $color-text-primary;
        }
    }
}
