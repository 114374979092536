@import "variables", "media-queries", "mixins", "typography";
.section {
    background-image: $gradient-second;
    @include section-indent;
}

.heading {
    margin-bottom: 60px;
}

.cardsWrap {
    overflow: auto;
    padding-bottom: 20px;
    color: $color-text-primary;

    @include customize-scrollbars();

    @include media-breakpoint(1230px) {
        margin-left: -1 * $container-indent;
        margin-right: -1 * $container-indent;
        padding-left: $container-indent;
        padding-right: $container-indent;
    }

    @include media-breakpoint(sm) {
        margin-left: -1 * $container-indent-sm;
        margin-right: -1 * $container-indent-sm;
        padding-left: $container-indent-sm;
        padding-right: $container-indent-sm;
    }
}

.cardsWrapInner {
    display: grid;
    grid-template-columns: repeat(3, 370px);
    grid-gap: 30px;
    // width: 1170px;

    @include media-breakpoint(sm) {
        grid-template-columns: repeat(3, 300px);
        grid-gap: 15px;
    }
}

.buttons {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    padding-top: 40px;

    @include media-breakpoint(xs) {
        flex-direction: column;
        padding-top: 30px;
    }

    .button {
        &:not(:last-child) {
            margin-right: 30px;

            @include media-breakpoint(xs) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        @include media-breakpoint(xs) {
            width: 100%;
        }
    }
}
