@import "variables", "media-queries", "mixins", "typography";
.card {
    max-width: 570px;
    min-height: 380px;
    width: 100%;
    border-radius: $radius-default;
    background-image: $gradient-cta;
    border: 1px solid $color-misc-card-glass-border;
    box-shadow: $shadows-card-cta-default;
    color: $color-text-primary;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.25s;

    &:hover {
        box-shadow: $shadows-card-cta-hover;
        background-image: $gradient-cta-hover;
    }

    @include media-breakpoint(sm) {
        min-height: 300px;
        max-width: 330px;
        padding: 15px;
    }
}

.btnWrap {
    max-width: 200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.img {
    font-size: 0;
    line-height: 1;
    margin-bottom: 15px;
}

.center {
    text-align: center;
    max-width: 265px;
}

.text {
    @include body-big;
    font-weight: 600;
    margin-bottom: 25px;
}
