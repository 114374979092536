@import "variables", "media-queries", "mixins", "typography";
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    counter-reset: pagination-counter;

    &.colorDefault {
        .pagination {
            :global {
                .swiper-pagination-bullet {
                    background: $color-basic-primary-bg;

                    &.swiper-pagination-bullet-active {
                        background: $color-basic-primary;
                        box-shadow: $shadows-button-primary;
                    }
                }
            }
        }
    }

    &.colorWhite {
        .pagination {
            :global {
                .swiper-pagination-bullet {
                    background: $color-white;

                    &.swiper-pagination-bullet-active {
                        background: $color-basic-primary;
                        box-shadow: $shadows-button-primary;
                    }
                }
            }
        }
    }

    .button {
        cursor: pointer;
        width: 40px;
        height: 40px;

        &Left {
            svg {
                position: relative;
                top: 1px;
                left: -1px;
            }

            @include media-breakpoint(md) {
                margin-right: 30px;
            }
        }

        &Right {
            svg {
                position: relative;
                top: 1px;
                right: -1px;
            }

            @include media-breakpoint(md) {
                margin-left: 30px;
            }
        }
    }
}

.pagination {
    text-align: center;
    margin-right: 16px;
    margin-left: 16px;
    display: flex;
    align-items: center;

    @include media-breakpoint(md) {
        display: none;
    }

    :global {
        .swiper-pagination-bullet {
            position: relative;
            opacity: 1;
            width: 10px;
            height: 10px;
            margin: 0 5px;
            border-radius: 50%;
            box-shadow: $shadows-general-small;
        }
    }
}
