@import "variables", "media-queries", "mixins", "typography";
.sliderInner {
    position: relative;
    width: 570px;

    @include media-breakpoint(md) {
        width: 360px;
    }

    @include media-breakpoint(sm) {
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
}

.mocked {
    display: flex;

    > div {
        flex-shrink: 0;
        margin-right: 30px;

        @include media-breakpoint(xs) {
            margin-right: 15px;
        }
    }
}

.slider {
    padding-bottom: 60px;
    overflow: visible;

    @include media-breakpoint(sm) {
        padding-bottom: 30px;
    }

    :global {
        .swiper-slide {
            height: auto;
        }
    }
}

.card {
    height: 100%;
}
.freeChatCard {
    min-height: none;
    min-height: auto;
    height: 100%;
}
