@import "variables", "media-queries", "mixins", "typography";
.section {
    background-image: $gradient-first;
    @include section-indent;
}

.heading {
    margin-bottom: 40px;
}

.list {
    columns: 2;
    max-width: 970px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;

    @include media-breakpoint(sm) {
        columns: 1;
        margin-bottom: 30px;
    }

    > li {
        max-width: 435px;
    }
}

.bottomTitle {
    @include body-small-caps;
    margin-bottom: 25px;
    color: $color-status-muted;
}

.bottomHeading {
    margin-bottom: 25px;
}

.bottom {
    max-width: 830px;
    margin: 0 auto;
    text-align: center;

    @include media-breakpoint(sm) {
        text-align: left;
    }
}

.btnWrap {
    max-width: 184px;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint(sm) {
        margin-left: auto;
    }
}
