@import "variables", "media-queries", "mixins", "typography";
.wrapper {
    display: inline-flex;
    text-align: center;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    justify-content: center;

    i {
        font-size: inherit;
    }

    &.withText {
        font-size: 16px;
        font-weight: 700;
        line-height: 42px;
    }

    &.withNumber {
        width: 64px;
        height: 64px;
        line-height: 64px;
    }
}

.number {
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 4px solid $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sizeDefault {
    width: 54px;
    height: 54px;
    line-height: 54px;

    svg {
        width: 30px;
        height: 30px;
    }

    &.shapeCircle {
        width: 42px;
        height: 42px;
        line-height: 42px;
    }
}

.sizeSmall {
    font-size: 18px;
    line-height: 18px;
    width: 20px;
    height: 20px;

    &.withText {
        line-height: 20px;
    }
}

.sizeMiddle {
    width: 23px;
    height: 23px;

    &.shapeSquare {
        border-radius: 6px;
    }

    i {
        line-height: 23px;
    }
}

.typeDefault {
    color: $color-basic-primary;
    background-color: $color-white;
    box-shadow: $shadows-inner;
}

.typeWhite {
    color: $color-basic-primary;
    background-color: $color-white;
    box-shadow: $shadows-general-small;

    .number {
        background-color: $color-white;
    }
}

.typePrimary {
    color: $color-white;
    background-color: $color-basic-primary;
    box-shadow: $shadows-button-primary;

    .number {
        background-color: $color-basic-primary;
    }

    &.shapeCircle {
        background-color: transparent;
        box-shadow: none;
        z-index: 1;

        .addShadow {
            background: linear-gradient(180.18deg, #9eafff 55.94%, #6d86ff 99.84%);
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            box-shadow: 0px -8px 18px -5px #00188b inset;
            background: linear-gradient(172.02deg, #98a9ff -20.06%, #2d4eed 93.85%);
            border-radius: 50%;
            z-index: -1;
        }

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(180.05deg, rgba(153, 170, 252, 0) 0.04%, #2d4eed 100.94%);
            border-radius: 50%;
            z-index: -2;
        }
    }
}

//background: linear-gradient(180.05deg, rgba(153, 170, 252, 0) 0.04%, #2D4EED 100.94%);
//background: linear-gradient(180.18deg, #9EAFFF 55.94%, #6D86FF 99.84%);

.typePrimaryLight {
    color: $color-basic-primary;
    background-color: $color-gray-light;
    box-shadow: $shadows-general-small;

    .number {
        background-color: $color-gray-light;
    }
}

.typeGreen {
    color: $color-white;
    background-color: $color-green;
    box-shadow: $shadows-button-green;

    .number {
        background-color: $color-green;
    }
}

.typeYellow {
    color: $color-white;
    background-color: $color-orange;
    box-shadow: $shadows-button-primary;

    .number {
        background-color: $color-orange;
    }
}

.typeRed {
    color: $color-white;
    background-color: $color-red;
    box-shadow: $shadows-button-red;

    .number {
        background-color: $color-red;
    }
}

.typeGreenLight {
    color: $color-green;
    background-color: $color-green-bg;
    border: 1px solid $color-green-light;

    .number {
        background-color: $color-green-bg;
    }
}

.typeWhiteTransparent {
    color: $color-white;
    border: 1px solid $color-basic-border;
    box-shadow: $shadows-general-small;

    .number {
        background-color: $color-white;
    }
}

.shapeSquare {
    border-radius: $radius-default;
}

.shapeCircle {
    border-radius: 50%;
}

.addShadow {
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    filter: blur(5px);

    z-index: -3;
}
