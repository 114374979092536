@import "variables", "media-queries", "mixins", "typography";
// PreviewAlert

.wrapper {
    z-index: 999;
    position: sticky;
    bottom: 0;
    padding: 10px 0;
    background-color: $color-status-warning;

    a {
        color: $color-basic-primary;
        text-decoration: underline;
    }
}
