@import "variables", "media-queries", "mixins", "typography";
.wrapper {
    display: flex;
    justify-content: center;
}

.inner {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 13px 0;
    background-color: $color-misc-card-glass-bg;
    border: 1px solid $color-misc-card-glass-border;
    border-radius: $radius-big;
    box-shadow: $shadows-card-glass;
    transition: border 0.25s, box-shadow 0.25s;

    &:hover {
        border-color: $color-misc-card-glass-border-hover;
        box-shadow: $shadows-card-glass-hover;
    }

    @include media-breakpoint(md) {
        padding-left: 10px;
        padding-right: 10px;
    }

    @include media-breakpoint(sm) {
        padding: 0;
        max-width: 480px;
        justify-content: space-evenly;
    }
}

.item {
    margin-bottom: 20px;
    margin-left: 37px;
    margin-right: 37px;

    @include media-breakpoint(md) {
        margin-left: 10px;
        margin-right: 10px;
    }

    @include media-breakpoint(sm) {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    @include media-breakpoint(360px) {
        margin-left: 2px;
        margin-right: 2px;
    }

    // &:not(:last-child) {
    //     margin-right: 50px;

    //     @include media-breakpoint(sm) {
    //         margin-right: 20px;
    //     }
    // }
}
