@import "variables", "media-queries", "mixins", "typography";
.section {
    color: $color-white;
    @include section-indent;
    background-image: $gradient-first;
}

.heading {
    margin-bottom: 60px;

    @include media-breakpoint(md) {
        margin-bottom: 40px;
    }

    @include media-breakpoint(sm) {
        margin-bottom: 40px;
    }
}

.main {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 400px;

    @include media-breakpoint(lg) {
        grid-template-columns: 1fr;
    }
}

.card {
    padding: 20px;
    cursor: default;

    strong {
        display: block;
        margin-top: 20px;
        margin-bottom: 12px;
    }
}

.cardsWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px 34px;
    color: $color-text-primary;

    @include media-breakpoint(sm) {
        grid-template-columns: 1fr;
    }

    > * {
        &:nth-child(odd):last-child {
            grid-column: span 2;

            @include media-breakpoint(sm) {
                grid-column: span 1;
            }
        }
    }
}

.featuresCard {
    max-width: 100%;
}
