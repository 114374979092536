@import "variables", "media-queries", "mixins", "typography";
.section {
    @include section-indent;
    background-image: $gradient-first;
}

.heading {
    margin-bottom: 60px;
}

.cardsWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include media-breakpoint(sm) {
        grid-template-columns: 1fr;
        grid-gap: 15px;
    }
}

.bottom {
    padding-top: 20px;
    color: $color-status-muted;
    text-align: center;
}
