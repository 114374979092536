@import "variables", "media-queries", "mixins", "typography";
.section {
    background-image: $gradient-second;
    @include section-indent;
    overflow: hidden;
}

.heading {
    margin-bottom: 40px;
}
