@import "variables", "media-queries", "mixins", "typography";
.card {
    padding: 30px;
    color: $color-text-primary;
    width: 370px;

    @include media-breakpoint(md) {
        padding: 15px;
        width: 300px;
    }

    &Background {
        background-color: $color-white;
        box-shadow: $shadows-card-default;
    }
}

.top {
    display: grid;
    grid-template-columns: 54px 1fr;
    grid-gap: 20px;
}

.title {
    @include body-default-bold;
    margin-bottom: 4px;
    display: block;
}

.heading {
    margin-bottom: 20px;
}

.headingBottom {
    display: flex;
    align-items: center;
}

.date {
    @include body-small-semibold;
    color: $color-status-muted;
    margin-left: 10px;
}

.text {
    @include body-default;
}

.icon {
    max-width: 54px;
    height: auto;
}
