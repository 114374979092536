@import "variables", "media-queries", "mixins", "typography";
.section {
    @include section-indent;

    &.columns3 {
        .list {
            grid-template-columns: repeat(3, 1fr);
            max-width: 670px;

            @include media-breakpoint(sm) {
                grid-template-columns: 1fr;
            }

            @include media-breakpoint(sm) {
                max-width: 100%;
            }
        }
    }

    &.columns4 {
        .list {
            grid-template-columns: repeat(4, 1fr);
            max-width: 880px;

            @include media-breakpoint(sm) {
                grid-template-columns: 1fr;
            }

            @include media-breakpoint(sm) {
                max-width: 100%;
            }
        }
    }

    .tabContents {
        .list {
            // orphans: 1;
            &.listFullWidth {
                max-width: 100%;
            }

            &.listCol3 {
                grid-template-columns: repeat(3, 1fr);

                @include media-breakpoint(sm) {
                    grid-template-columns: 1fr;
                }
            }

            &.listCol4 {
                grid-template-columns: repeat(4, 1fr);

                @include media-breakpoint(sm) {
                    grid-template-columns: 1fr;
                }
            }
        }
    }
}

.heading {
    margin-bottom: 40px;
}

.tabsTitle {
    @include body-small-caps;
    color: $color-status-muted;
    text-align: center;
    margin-bottom: 30px;

    @include media-breakpoint(sm) {
        text-align: left;
    }
}

.tabButtons {
    overflow: auto;
    padding-top: 15px;
    margin-top: -15px;

    @include customize-scrollbars();

    @include media-breakpoint(sm) {
        margin-right: -1 * $container-indent-sm;
        margin-left: -1 * $container-indent-sm;
        padding-left: $container-indent-sm;
        padding-right: $container-indent-sm;
    }
}

.tabButtonsInner {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    @include media-breakpoint(sm) {
        justify-content: flex-start;

        &::after {
            content: "";
            display: block;
            flex-shrink: 0;
            width: $container-indent-sm;
        }
    }
}

.list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -20px;

    display: grid;
    gap: 0 20px;

    @include media-breakpoint(sm) {
        margin-bottom: -10px;
    }

    .listItem {
        margin-bottom: 20px;

        @include media-breakpoint(sm) {
            margin-bottom: 10px;
        }
    }

    &Active {
        position: relative;
        opacity: 1;
        pointer-events: auto;
    }
}

.tabContents {
    position: relative;
    margin-bottom: 50px;
}

.btnWrap {
    text-align: center;

    .button {
        @include media-breakpoint(sm) {
            width: 100%;
        }
    }
}

.itemIcon {
    margin-right: 12px;
}

.downloadListItem {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    a {
        display: inline-flex;
        align-items: center;
        gap: 16px;
        transition: color 0.25s;
    }

    &:hover {
        a {
            color: $color-basic-primary-hover;
        }
        .downloadIcon {
            box-shadow: 0 4px 4px 0 #{rgba(#181fcd, 0.35)};
        }
    }
}

.downloadIcon {
    border-radius: 50%;
    transition: box-shadow 0.25s;
}
