@import "variables", "media-queries", "mixins", "typography";
.card {
    max-width: 570px;
    border-radius: $radius-default;
    background-color: $color-misc-card-glass-bg;
    border: 1px solid $color-misc-card-glass-border;
    box-shadow: $shadows-card-glass;
    display: flex;
    color: $color-text-primary;
    width: 100%;
    transition: border 0.25s, box-shadow 0.25s;
    overflow: hidden;

    &.variantWritersPage {
        max-width: 100%;
        min-height: 310px;
    }

    &:hover {
        border-color: $color-misc-card-glass-border-hover;
        box-shadow: $shadows-card-glass-hover;

        .writer {
            background-color: #f8faff;
        }

        .review {
            background-color: #f1f6ff;
        }
    }

    @include media-breakpoint(md) {
        max-width: 360px;
    }

    @include media-breakpoint(sm) {
        max-width: 300px;
    }
}

.writer,
.review {
    width: 50%;
}

.review {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-color: $color-white;
    transition: background-color 0.25s;
    border-radius: 0 $radius-default $radius-default 0;
}

.writer {
    background-color: $color-white;
    padding: 30px;
    padding-right: 20px;
    box-shadow: $shadows-general-small;
    border-radius: $radius-default 0 0 $radius-default;
    //border: 1px solid $color-misc-card-glass-border;
    transition: background-color 0.25s;
    position: relative;

    @include media-breakpoint(md) {
        width: 100%;
    }

    &Top {
        margin-bottom: 20px;
        display: grid;
        grid-template-columns: 64px 1fr;
        grid-gap: 20px;
    }

    &Name {
        @include body-default-bold;
        margin-bottom: 5px;
        display: block;
    }

    &Rating {
        margin-bottom: 15px;
    }

    &ReviewsNum {
        @include body-small-semibold;
        margin-left: 0.5ch;
    }

    &Features {
        @include media-breakpoint(md) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &Feature {
        display: grid;
        grid-template-columns: 30px 1fr;
        grid-gap: 15px;

        @include media-breakpoint(md) {
            width: calc(50% - 15px);
        }

        &:nth-child(odd) {
            @include media-breakpoint(md) {
                margin-right: 15px;
            }
        }

        &:last-child {
            @include media-breakpoint(md) {
                width: 100%;
                margin-right: 0;
            }
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &Heading {
            @include body-small;
            color: $color-gray-dark;
        }

        &Text {
            @include body-default-bold;
        }
    }
}

.writerCompenteces {
    padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
}

.writerCompetence {
    cursor: default;
}

.review {
    padding: 30px;

    @include media-breakpoint(md) {
        display: none;
    }

    &Title {
        display: block;
        @include body-small-caps;
        color: $color-gray-dark;
        margin-bottom: 30px;
    }

    &Top {
        display: grid;
        grid-template-columns: 30px 1fr;
        align-items: center;
        grid-gap: 10px;
        margin-bottom: 10px;
    }

    &Name {
        @include body-small-bold;
        margin-bottom: 6px;
    }

    &Date {
        @include body-small;
        color: $color-gray-dark;
    }

    &Text {
        @include body-medium;
        margin-bottom: 28px;
    }
}

.writerImg {
    border-radius: 6px;
    overflow: hidden;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
}

.btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
    width: 100%;
}
