@import "variables", "media-queries", "mixins", "typography";
.section {
    @include section-indent;
    overflow: hidden;
    position: relative;

    :global {
        .sc-heading {
            text-align: center;

            .sc-heading__title {
                text-align: center;
            }
        }
    }
}

.heading {
    margin-bottom: 15px;
    max-width: 680px;
}

.btnWrap {
    margin-top: 30px;
    text-align: center;
}

.container {
    position: relative;
}

.bg {
    top: 0;
    width: 2540px;
    height: 1130px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    filter: blur(10px);
}
