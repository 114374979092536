@import "variables", "media-queries", "mixins", "typography";
// EditorContent

.wrapper {
    h1,
    h2 {
        margin-bottom: 30px;
        @include media-breakpoint(sm) {
            margin-bottom: 20px;
        }

        &:not(:first-child) {
            margin-top: 40px;
            @include media-breakpoint(sm) {
                margin-top: 30px;
            }
        }
    }
    h3,
    h4,
    h5 {
        margin-top: 30px;
        margin-bottom: 30px;
        @include media-breakpoint(sm) {
            margin-top: 30px;
            margin-bottom: 20px;
        }
    }

    a {
        color: $color-text-link;

        &:hover {
            text-decoration: underline;
        }
    }

    :global {
        .upper-line {
            text-decoration: overline;
        }

        .center-text {
            text-align: center;
        }
    }

    hr {
        margin: 20px 0;
    }

    sup,
    sub {
        font-size: 75%;
        white-space: nowrap;
    }

    sup {
        vertical-align: super;
    }

    sub {
        vertical-align: sub;
    }

    pre {
        max-width: 100%;
        overflow: auto;

        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid $color-basic-border;
        color: $color-text-secondary;
        font-weight: 400;

        @include body-medium;

        &:not(:first-child) {
            margin-top: 40px;
        }

        &:not(:last-child) {
            margin-bottom: 40px;
        }
    }

    table {
        @include table;
    }

    :global(.tableWithBackground) {
        tr {
            &:nth-child(even) {
                background-color: #f3f6ff;
            }
        }
    }
}

.left {
    text-align: left;
}
.right {
    text-align: right;
}
.center {
    text-align: center;
}
